import { useStaticQuery, graphql } from "gatsby"

export const useVoiceLanguages = () => {
  const data = useStaticQuery(graphql`
    query QueryVoiceLanguages {
      allQuakVoiceLanguages {
        nodes {
          language
          languageName
          languageNativeName
        }
      }
    }
  `)

  return data.allQuakVoiceLanguages.nodes
}
